import { H4, Stack } from '~/components-v2'
import { ProductCardProps, productCards } from '../data/prodcut-features-data'

const ProductCard = ({
  title,
  image,
  className,
  children,
}: ProductCardProps) => {
  return (
    <Stack className={`product-card ${className}`} gap={8}>
      <div className="product-card-image">
        <img src={image} alt="" loading="lazy" />
      </div>
      <Stack className="product-card-content" gap={4} padding="0,4">
        <H4 fontSize="xl" fontWeight={700}>
          {title}
        </H4>
        {children}
      </Stack>
    </Stack>
  )
}

export const ProductFeatureCards = () => {
  return (
    <section>
      <hgroup className="centered-section-title">
        <h3>Get more from your data</h3>
        <p>Omni helps companies of all sizes get insights fast.</p>
      </hgroup>

      <section className="grid cols3 product-feature-cards">
        {productCards.map((card) => (
          <ProductCard key={card.title} {...card} />
        ))}
      </section>
    </section>
  )
}
