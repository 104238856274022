import { H5, P, Stack } from '~/components-v2'
import { caseStudyCalloutData } from '../data/case-study-callouts'
import { Link } from '@remix-run/react'

export const CaseStudyCalloutCards = () => {
  return (
    <section>
      <hgroup className="centered-section-title">
        <h3>Make every team data-driven</h3>
        <p>Say goodbye to technical barriers and slow support.</p>
      </hgroup>

      <div className="grid cols3 case-study-call-out-cards">
        {caseStudyCalloutData.map((callout) => (
          <div
            key={callout.title}
            className={`case-study-callout ${callout.className}`}
          >
            <div className="image-container">
              <img src={callout.image} alt="" loading="lazy" />
            </div>
            <Stack className="content-container" padding="4,6" gap={6} grow={1}>
              <H5 fontSize="xxl" fontWeight={700}>
                {callout.title}
              </H5>
              <P>{callout.subTitle}</P>
              <hr />
              <img src={callout.logo} alt="" className="case-study-logo" />
              <P>{callout.caseStudyBlurb}</P>
              <Link className="case-study-button" to={callout.caseStudyUrl}>
                Read the case study
              </Link>
            </Stack>
          </div>
        ))}
      </div>
    </section>
  )
}
