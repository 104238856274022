import { customerLogos } from '~/config/customers'

export type CaseStudyCalloutProps = {
  title: string
  subTitle: string
  className: string
  image: string
  logo: string
  caseStudyBlurb: string
  caseStudyUrl: string
}

export const caseStudyCalloutData: CaseStudyCalloutProps[] = [
  {
    title: 'Company-wide KPIs',
    subTitle: 'Track and share key metrics to keep everyone on the same page.',
    className: 'company-wide-kpis',
    image: '/images/pages/home/case-study-callouts/company-wide-kpis.webp',
    logo: customerLogos.ascend,
    caseStudyBlurb: 'Achieved 100% adoption in less than 6 weeks.',
    caseStudyUrl: '/blog/case-study-ascend',
  },
  {
    title: 'Product tracking',
    subTitle:
      'Get better insight into customer behavior to drive adoption and retention.',
    className: 'product-tracking',
    image: '/images/pages/home/case-study-callouts/product-tracking.webp',
    logo: customerLogos.roseRocket,
    caseStudyBlurb: 'Product managers easily explore unstructured data.',
    caseStudyUrl: '/blog/case-study-rose-rocket',
  },
  {
    title: 'Customer success',
    subTitle:
      'Identify what your customers need and how to best help them get value.',
    className: 'customer-success',
    image: '/images/pages/home/case-study-callouts/customer-success.webp',
    logo: customerLogos.incidentio,
    caseStudyBlurb:
      'Customer Success Managers use Customer Health dashboards to provide a tailored experience.',
    caseStudyUrl: '/blog/case-study-incident-io',
  },
  {
    title: 'Sales reporting',
    subTitle: 'Analyze pipeline and uncover new ways to drive more revenue.',
    className: 'sales-reporting',
    image: '/images/pages/home/case-study-callouts/sales-reporting.webp',
    logo: customerLogos.incidentio,
    caseStudyBlurb:
      'Sales reps use personalized dashboards with deal metrics and call recordings to close more business. ',
    caseStudyUrl: '/blog/case-study-incident-io',
  },
  {
    title: 'Marketing metrics',
    subTitle:
      'Identify customer cohorts, improve your funnel, and measure ROI.',
    className: 'marketing-metrics',
    image: '/images/pages/home/case-study-callouts/marketing-metrics.webp',
    logo: customerLogos.uscreen,
    caseStudyBlurb:
      'Creators on the Uscreen platform track what content engages their community.',
    caseStudyUrl: '/blog/case-study-uscreen',
  },
  {
    title: 'Customer-facing reports',
    subTitle:
      'Seamlessly integrate beautiful, real-time dashboards and workbooks into your product.',
    className: 'embedded-analytics',
    image: '/images/pages/home/case-study-callouts/embedded-analytics.webp',
    logo: customerLogos.primaryBid,
    caseStudyBlurb: 'Built a modern data product in less than one week.',
    caseStudyUrl: '/blog/case-study-primarybid',
  },
]
