import { P } from '~/components-v2'
import { Link } from '@remix-run/react'

export type ProductCardProps = {
  title: string
  image: string
  className: string
  children: React.ReactNode
}

export const productCards: ProductCardProps[] = [
  {
    title: 'Self-service analytics for everyone',
    image: '/images/pages/home/product-features/ai-input.webp',
    className: 'self-service-analytics',
    children: (
      <>
        <P fontSize="sm">
          Explore data your way with Omni's point-and-click UI,{' '}
          <Link to="/calculations">spreadsheets</Link>, AI, or SQL.
        </P>
        <P fontSize="sm">
          Run ad-hoc analyses, interact with pre-built reports, and get updates
          directly within your workflow.
        </P>
      </>
    ),
  },

  {
    title: 'Seamless embedded analytics',
    image: '/images/pages/home/product-features/gleam.webp',
    className: 'embedded-analytics',
    children: (
      <>
        <P fontSize="sm">
          Build powerful, on-brand{' '}
          <Link to="/embedded-analytics">customer-facing data products</Link> in
          weeks, not months.
        </P>
        <P fontSize="sm">
          Iterate, reuse, and maintain customer-facing reports without wasting
          engineering resources.
        </P>
      </>
    ),
  },
  {
    title: 'A data model that doesn’t slow you down',
    image: '/images/pages/home/product-features/model-schema.webp',
    className: 'fast-data-modeling',
    children: (
      <>
        <P fontSize="sm">
          Ensure consistency with a governed{' '}
          <Link to="/data-modeling">semantic layer</Link> that keeps everyone
          aligned.
        </P>
        <P fontSize="sm">
          Quickly explore data, refine logic, and codify it into dbt with our{' '}
          <Link to="https://docs.omni.co/docs/integrations/dbt">
            robust dbt integration
          </Link>
          .
        </P>
      </>
    ),
  },
  {
    title: 'Enterprise-grade security and administration',
    image: '/images/pages/home/product-features/model-access-grants.webp',
    className: 'enterprise-grade-administration',
    children: (
      <>
        <P fontSize="sm">
          Safely scale access with row- and column-level permissions, user
          attributes, SSO, and more.
        </P>
        <P fontSize="sm">
          Give everyone data access while maintaining{' '}
          <Link to="/security">security</Link> and accuracy.
        </P>
      </>
    ),
  },

  {
    title: 'Integrations with the tools you already love',
    image: '/images/pages/home/product-features/integration-logos.webp',
    className: 'integrations',
    children: (
      <>
        <P fontSize="sm">
          Data teams use Omni with{' '}
          <Link to="/integrations">modern cloud-based platforms</Link>, like
          Snowflake and dbt.
        </P>
        <P fontSize="sm">
          Omni's integrations and{' '}
          <Link to="https://docs.omni.co/docs/API/overview">APIs</Link> give you
          flexibility to work how you want — even when you're not in our app.
        </P>
      </>
    ),
  },
  {
    title: 'Trusted partner for all your analytics needs',
    image: '/images/pages/home/product-features/quotes.webp',
    className: 'trusted-partner',
    children: (
      <>
        <P fontSize="sm">
          Get real-time answers and{' '}
          <Link to="/customer-support">hands-on help</Link> for even your
          toughest challenges, directly in Slack or Teams.
        </P>
        <P fontSize="sm">
          Our team of data experts is dedicated to your success — every step of
          the way.
        </P>
      </>
    ),
  },
]
